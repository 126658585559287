import React, { useEffect, useState } from "react";
import get from "lodash/get";
import FloorplanPoliciesView from "../../components/FloorplanPoliciesView";
import FloorplanPoliciesTabView from "../../components/FloorplanPoliciesTabView";
import FloorplanPoliciesItemView from "../../components/FloorplanPoliciesItemView";
import { personaColorStyle } from "../../helpers/property/persona";
import styles from "./styles.module.css";
import "./index.css";
import { LazyLoaderElement, LazyLoader } from "../../helpers/lazy-loader";

function FloorplanPolicies(props) {

    const orderSort = (a, b) => (a.sortOrder > b.sortOrder ? 1 : -1);
    const [tab, setTab] = useState(0);
    const upfront_fees = get(props, "property.data.addOns", []).filter((a) => a.category === 'Upfront fees').sort(orderSort);
    const pets = get(props, "property.data.addOns", []).filter((a) => a.category === 'Pets').sort(orderSort);
    const parking = get(props, "property.data.addOns", []).filter((a) => a.category === 'Parking').sort(orderSort);
    const storage = get(props, "property.data.addOns", []).filter((a) => a.category === 'Storage').sort(orderSort);
    const other = get(props, "property.data.addOns", []).filter((a) => a.category === 'Other').sort(orderSort);
    const tabsdata = [upfront_fees, pets, parking, storage, other];
    const tabs = ["Upfront Costs", "Pets", "Parking", "Storage", "Other"];
    const property = get(props, 'property');

    const hasData = tabsdata.filter(items => !!items && items.length > 0).length > 0;
    if (!hasData) {
        return null;
    }

    useEffect(() => {
        //#region SETUP lazy loading

        let lazyLoaderElementList = [];

        let lazyLoaderElementItem = new LazyLoaderElement();

        //STORE images TO lazy load
        lazyLoaderElementItem.Id = "floorplan_policy_image";
        lazyLoaderElementItem.ClassName = "af-class-div-block-200 " + styles.backgroundImage;

        lazyLoaderElementList.push(lazyLoaderElementItem);

        const lazyLoader = new LazyLoader();
        lazyLoader.LazyLoad(lazyLoaderElementList);

        //#endregion

    }, [])

    return (
        <FloorplanPoliciesView>
            <header style={personaColorStyle(property, 'secondary')}>Additional Costs & Add-Ons</header>
            <title style={personaColorStyle(property, 'primary')} />
            <image-wrapper  />
            <floorplanPoliciesTabViewList0>
                {tabs.map((item, index) =>
                    !!tabsdata[index] && tabsdata[index].length > 0 ? (
                        <FloorplanPoliciesTabView.Controller
                            property={property}
                            title={item}
                            key={`${tabs[tab]}_tab_${index}`}
                            active={tab === tabs.indexOf(item)}
                            onClick={() => setTab(tabs.indexOf(item))}
                        />
                    ) : null
                )}
            </floorplanPoliciesTabViewList0>
            <floorplanPoliciesItemViewList4>
                {tabsdata[tab].map((item, index) => (
                    <FloorplanPoliciesItemView.Controller 
                        property={props.property} 
                        key={`${tabs[tab]}_item_${index}`} 
                        item={item} 
                    />
                ))}
                <div className={styles.disclaimer}>
                    {tabs[tab] === 'Pets' ? '* 2 pet maximum.' : '* Prices may vary by unit.'}
                </div>
            </floorplanPoliciesItemViewList4>
        </FloorplanPoliciesView>
    );
}

export default FloorplanPolicies;
