import { useGeolocation } from '../../helpers/hooks/useGeolocation';
import styles from './styles.module.css';
import { withRouter } from 'react-router-dom';

const React = require('react');

// This component won't do anything when not rendered on server side.
// The script that adds functionality is only added server side.
// This is done to ensure the script is loaded first so it is able to block other scripts & cookies.

// The reason this component is needed is because the script runs on the server side, but doesn't rerender on the client side.
// This causes the script to always default to the region where the server is hosted, so the text on the button doesn't accurately reflect the region of the user.

// We can use https://www.geojs.io/docs/v1/endpoints/geo/ to get the region of the user.
// This is done in the useGeolocation hook and currently has no rate limits.
const CaliforniaPrivacyComponent = ({ location }) => {
  const { region } = useGeolocation();

  // Override the region by using the otgeo query parameter.
  const overrideRegion = location.search.includes('otgeo=us,ca');

  return (
    <>
      {region === 'California' || overrideRegion ? (
        <button onClick={() => window.OneTrust.ToggleInfoDisplay()} id="ot-sdk-btn" className={`ot-sdk-show-settings ${styles.otsdkshowsettings}`}>
          Do Not Sell or Share My Personal Information
        </button>
      ) : (
        <button onClick={() => window.OneTrust.ToggleInfoDisplay()} id="ot-sdk-btn" className={`ot-sdk-show-settings ${styles.otsdkshowsettings}`}>
          Cookie Settings
        </button>
      )}
    </>
  );
};

export default withRouter(CaliforniaPrivacyComponent);
